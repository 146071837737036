import React, { useEffect, useState, useRef, lazy, Suspense } from 'react'
import './App.css'
import MySideNav from './component/MySideNav'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import Login from './pages/Login'
import Welcome from './pages/Welcome'
import Expired from './pages/Expired'
import usePersistentState from './component/usePersistentState'
import { createState } from 'state-pool'
import globalVars from './variables/globalVars'
import parseJwt from './library/parseJWT'
import Cookies from 'js-cookie'
import './component/EditorStyles.css'
import './Table/CustomerGrid.css'
import { ErrorBoundary } from 'react-error-boundary'
import { Dialog } from 'primereact/dialog'
import { useProSidebar } from 'react-pro-sidebar'

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css'

//core
import 'primereact/resources/primereact.min.css'
//fontawesome pro
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/sharp-solid.css'
import '@fortawesome/fontawesome-pro/css/sharp-light.css'
import '@fortawesome/fontawesome-pro/css/brands.css'
import '@fortawesome/fontawesome-pro/css/regular.css'

const Customers = lazy(() => import('./pages/Customers'))
const CustomerPage = lazy(() => import('./pages/CustomerPage'))
const Products = lazy(() => import('./pages/Products'))
const ProductPage = lazy(() => import('./pages/ProductPage'))
const Versions = lazy(() => import('./pages/Versions'))
const VersionPage = lazy(() => import('./pages/VersionPage'))
const RemoteConnections = lazy(() => import('./pages/RemoteConnections'))
const SystemPasswords = lazy(() => import('./pages/SystemPasswords'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const ForecastRevenue = lazy(() => import('./pages/ForecastRevenue'))
const NotInvoiced = lazy(() => import('./pages/NotInvoiced'))
const DeferredValue = lazy(() => import('./pages/DeferredValue'))
const Users = lazy(() => import('./pages/Users'))
const UserPage = lazy(() => import('./pages/UserPage'))
const Groups = lazy(() => import('./pages/Groups'))
const GroupPage = lazy(() => import('./pages/GroupPage'))
const Access = lazy(() => import('./pages/Access'))
const Maintenance = lazy(() => import('./pages/Maintenance'))
const WhatsNew = lazy(() => import('./pages/WhatsNew'))
const BOSS = lazy(() => import('./pages/BOSS'))
const Schema = lazy(() => import('./pages/Schema'))
// const Options = lazy(() => import('./pages/Options'))

function App() {
    const getCollapsedState = () => {
        const tokenString = localStorage.getItem('sidebar-collapsed')
        const userCollasped = JSON.parse(tokenString)
        if (userCollasped === null) {
            return true
        }
        return userCollasped
    }

    const [errorDialogVisible, setErrorDialogVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { collapseSidebar } = useProSidebar()

    const handleError = (errorMsg) => {
        if (errorMsg !== 'Failed to fetch') {
            console.log(errorMsg)
            setErrorMessage(errorMsg)
            setErrorDialogVisible(true)
        }
    }

    const [collapsed, setCollapsed] = useState(getCollapsedState())
    const [defaultCollapsed, setdefaultCollapsed] = useState(true)
    const [toggled, setToggled] = useState(false)
    const toast = useRef(null)
    const toastLogin = useRef(null)
    const [editMode, setEditMode] = useState(false)
    const [serverDown, setServerDown] = useState(false)

    const [customerGridFilter, setCustomerGridFilter] = useState(undefined)
    const [customerGlobalFilterValue, setCustomerGlobalFilterValue] = useState('')

    const [customerContactGridFilter, setCustomerContactGridFilter] = useState(undefined)
    const [customerContactGlobalFilterValue, setCustomerContactGlobalFilterValue] = useState('')

    const [customerVersionGridFilter, setCustomerVersionGridFilter] = useState(undefined)
    const [customerVersionGlobalFilterValue, setCustomerVersionGlobalFilterValue] = useState('')

    const [customerNotesGridFilter, setCustomerNotesGridFilter] = useState(undefined)
    const [customerNotesGlobalFilterValue, setCustomerNotesGlobalFilterValue] = useState('')

    const [productGridFilter, setProductGridFilter] = useState(undefined)
    const [productGlobalFilterValue, setProductGlobalFilterValue] = useState('')

    const [productVersionGridFilter, setProductVersionGridFilter] = useState(undefined)
    const [productVersionGlobalFilterValue, setProductVersionGlobalFilterValue] = useState('')

    const [versionGridFilter, setVersionGridFilter] = useState(undefined)
    const [versionGlobalFilterValue, setVersionGlobalFilterValue] = useState('')

    const [userGridFilter, setUserGridFilter] = useState(undefined)
    const [userGlobalFilterValue, setUserGlobalFilterValue] = useState('')

    const [groupGridFilter, setGroupGridFilter] = useState(undefined)
    const [groupGlobalFilterValue, setGroupGlobalFilterValue] = useState('')

    const [bOSSFilterValue, setBOSSFilterValue] = useState('')

    const [preAuthNotes, setPreAuthNotes] = useState('')

    const [userId, setUserId] = usePersistentState(0, 'localstorage-User_ID')

    let [token, setToken] = usePersistentState('', 'localstorage-token')
    let [jwt, setJwt] = usePersistentState('', 'localstorage-jwt')
    let [email, setEmail] = usePersistentState('', 'localstorage-Email')
    let [jwtExpired, setJwtExpired] = usePersistentState(false, 'localstorage-JwtExpired')

    let [customerRow, setCustomerRow] = usePersistentState('', 'localstorage-CustomerRow')
    let [contactRow, setContactRow] = usePersistentState('', 'localstorage-ContactRow')
    let [customerVersionRow, setCustomerVersionRow] = usePersistentState('', 'localstorage-CustomerVersionRow')
    let [customerNotesRow, setCustomerNotesRow] = usePersistentState('', 'localstorage-CustomerNotesRow')
    let [customerConnectionNotesRow, setCustomerConnectionNotesRow] = usePersistentState('', 'localstorage-CustomerConnectionNotesRow')
    let [customerConnectionTOTPRow, setCustomerConnectionTOTPRow] = usePersistentState('', 'localstorage-CustomerConnectionTOTPRow')
    let [customerConnectionRemoteRow, setCustomerConnectionRemoteRow] = usePersistentState('', 'localstorage-CustomerConnectionRemoteRow')
    let [customerConnectionWebRow, setCustomerConnectionWebRow] = usePersistentState('', 'localstorage-CustomerConnectionWebRow')
    let [customerConnectionCloudRow, setCustomerConnectionCloudRow] = usePersistentState('', 'localstorage-CustomerConnectionCloudRow')

    let [productRow, setProductRow] = usePersistentState('', 'localstorage-ProductRow')
    let [productVersionRow, setProductVersionRow] = usePersistentState('', 'localstorage-ProductVersionRow')

    let [moduleRow, setModuleRow] = usePersistentState('', 'localstorage-ModuleRow')

    let [versionRow, setVersionRow] = usePersistentState('', 'localstorage-VersionRow')

    let [userRow, setUserRow] = usePersistentState('', 'localstorage-UserRow')

    let [groupRow, setGroupRow] = usePersistentState('', 'localstorage-GroupRow')

    let [remoteRow, setRemoteRow] = usePersistentState('', 'localstorage-RemoteRow')

    let [currentLogin, setCurrentLogin] = usePersistentState('', 'localstorage-CurrentLogin')

    let [customerTabIndex, setCustomerTabIndex] = usePersistentState(0, 'localstorage-CustomerTabIndex')
    let [productTabIndex, setProductTabIndex] = usePersistentState(0, 'localstorage-ProductTabIndex')
    let [customerConnectionTabIndex, setCustomerConnectionTabIndex] = usePersistentState(0, 'localstorage-CustomerConnectionTabIndex')

    let [dashboardTabIndex, setDashboardTabIndex] = usePersistentState(0, 'localstorage-DashboardTabIndex')

    let [bossCardMode, setBossCardMode] = usePersistentState('mini', 'localstorage-BossCardMode')

    let [bossShowQAMode, setBossShowQAMode] = usePersistentState(false, 'localstorage-BossShowQAMode')

    const cookieEmail = Cookies.get('email')

    if (cookieEmail !== undefined && email === undefined) {
        setEmail(cookieEmail)
    }

    if (jwt !== null && jwt !== undefined && jwt !== '' && toastLogin !== undefined) {
        const decodedJwt = parseJwt(jwt)

        if (decodedJwt.exp * 1000 < Date.now()) {
            setToken()
            setJwt()
            if (toastLogin !== undefined) {
                toastLogin.current.show({ severity: 'Login', summary: 'Login', detail: `Login has expired, please re-login.`, life: 3000 })
            }
        }
    }

    globalVars.userID = userId
    globalVars.jwt = jwt
    globalVars.email = email
    globalVars.jwtExpired = jwtExpired

    globalVars.customerRow = customerRow
    globalVars.contactRow = contactRow
    globalVars.customerVersionRow = customerVersionRow
    globalVars.customerNotesRow = customerNotesRow
    globalVars.customerConnectionNotesRow = customerConnectionNotesRow
    globalVars.customerConnectionTOTPRow = customerConnectionTOTPRow
    globalVars.customerConnectionRemoteRow = customerConnectionRemoteRow
    globalVars.customerConnectionWebRow = customerConnectionWebRow
    globalVars.customerConnectionCloudRow = customerConnectionCloudRow

    globalVars.productRow = productRow
    globalVars.productVersionRow = productVersionRow

    globalVars.moduleRow = moduleRow

    globalVars.versionRow = versionRow

    globalVars.userRow = userRow

    globalVars.groupRow = groupRow

    globalVars.remoteRow = remoteRow

    globalVars.currentLogin = currentLogin
    globalVars.serverDown = serverDown

    globalVars.customerTabIndex = customerTabIndex
    globalVars.productTabIndex = productTabIndex
    globalVars.customerConnectionTabIndex = customerConnectionTabIndex

    globalVars.dashboardTabIndex = dashboardTabIndex

    globalVars.setCustomerTabIndex = setCustomerTabIndex
    globalVars.setProductTabIndex = setProductTabIndex
    globalVars.setCustomerConnectionTabIndex = setCustomerConnectionTabIndex

    globalVars.setDashboardTabIndex = setDashboardTabIndex

    globalVars.bOSSFilterValue = bOSSFilterValue
    globalVars.setBOSSFilterValue = setBOSSFilterValue

    globalVars.preAuthNotes = preAuthNotes
    globalVars.setPreAuthNotes = setPreAuthNotes

    globalVars.customerGridFilter = customerGridFilter
    globalVars.customerGlobalFilterValue = customerGlobalFilterValue
    globalVars.customerContactGridFilter = customerContactGridFilter
    globalVars.customerContactGlobalFilterValue = customerContactGlobalFilterValue
    globalVars.customerVersionGridFilter = customerVersionGridFilter
    globalVars.customerVersionGlobalFilterValue = customerVersionGlobalFilterValue
    globalVars.customerNotesGridFilter = customerNotesGridFilter
    globalVars.customerNotesGlobalFilterValue = customerNotesGlobalFilterValue

    globalVars.productGridFilter = productGridFilter
    globalVars.productGlobalFilterValue = productGlobalFilterValue
    globalVars.productVersionGridFilter = productVersionGridFilter
    globalVars.productVersionGlobalFilterValue = productVersionGlobalFilterValue

    globalVars.versionGridFilter = versionGridFilter
    globalVars.versionGlobalFilterValue = versionGlobalFilterValue

    globalVars.userGridFilter = userGridFilter
    globalVars.userGlobalFilterValue = userGlobalFilterValue

    globalVars.groupGridFilter = groupGridFilter
    globalVars.groupGlobalFilterValue = groupGlobalFilterValue

    globalVars.setUserId = setUserId
    globalVars.setCurrentLogin = setCurrentLogin
    globalVars.setServerDown = setServerDown
    globalVars.setJwt = setJwt
    globalVars.setEmail = setEmail
    globalVars.setToken = setToken

    globalVars.setCustomerRow = setCustomerRow
    globalVars.setContactRow = setContactRow
    globalVars.setCustomerVersionRow = setCustomerVersionRow
    globalVars.setCustomerNotesRow = setCustomerNotesRow
    globalVars.setCustomerConnectionNotesRow = setCustomerConnectionNotesRow
    globalVars.setCustomerConnectionTOTPRow = setCustomerConnectionTOTPRow
    globalVars.setCustomerConnectionRemoteRow = setCustomerConnectionRemoteRow
    globalVars.setCustomerConnectionWebRow = setCustomerConnectionWebRow
    globalVars.setCustomerConnectionCloudRow = setCustomerConnectionCloudRow

    globalVars.setProductRow = setProductRow
    globalVars.setProductVersionRow = setProductVersionRow

    globalVars.setModuleRow = setModuleRow

    globalVars.setVersionRow = setVersionRow

    globalVars.setUserRow = setUserRow

    globalVars.setGroupRow = setGroupRow

    globalVars.setRemoteRow = setRemoteRow

    globalVars.setJwtExpired = setJwtExpired
    globalVars.setEditMode = setEditMode

    globalVars.setCustomerGridFilter = setCustomerGridFilter
    globalVars.setCustomerGlobalFilterValue = setCustomerGlobalFilterValue
    globalVars.setCustomerContactGridFilter = setCustomerContactGridFilter
    globalVars.setCustomerContactGlobalFilterValue = setCustomerContactGlobalFilterValue
    globalVars.setCustomerVersionGridFilter = setCustomerVersionGridFilter
    globalVars.setCustomerVersionGlobalFilterValue = setCustomerVersionGlobalFilterValue
    globalVars.setCustomerNotesGridFilter = setCustomerNotesGridFilter
    globalVars.setCustomerNotesGlobalFilterValue = setCustomerNotesGlobalFilterValue

    globalVars.setProductGridFilter = setProductGridFilter
    globalVars.setProductGlobalFilterValue = setProductGlobalFilterValue
    globalVars.setProductVersionGridFilter = setProductVersionGridFilter
    globalVars.setProductVersionGlobalFilterValue = setProductVersionGlobalFilterValue

    globalVars.setVersionGridFilter = setVersionGridFilter
    globalVars.setVersionGlobalFilterValue = setVersionGlobalFilterValue

    globalVars.setUserGridFilter = setUserGridFilter
    globalVars.setUserGlobalFilterValue = setUserGlobalFilterValue

    globalVars.setGroupGridFilter = setGroupGridFilter
    globalVars.setGroupGlobalFilterValue = setGroupGlobalFilterValue

    globalVars.handleError = handleError

    globalVars.setErrorMessage = setErrorMessage

    globalVars.bossCardMode = bossCardMode
    globalVars.setBossCardMode = setBossCardMode

    globalVars.bossShowQAMode = bossShowQAMode
    globalVars.setBossShowQAMode = setBossShowQAMode

    createState('jwt', jwt)
    createState('email', email)

    useEffect(() => {}, [customerRow, contactRow, versionRow, productRow, productVersionRow, customerNotesRow, customerVersionRow, userRow, groupRow, errorDialogVisible])

    useEffect(() => {
        // eslint-disable-next-line
    }, [collapsed])

    useEffect(() => {}, [jwt, jwtExpired, defaultCollapsed])

    useEffect(() => {
        if (serverDown === true) {
            setErrorMessage('Cannot communicate with the CASPA service. Please try again later.')
            setErrorDialogVisible(true)
        }
    }, [serverDown])

    const contentStyle = {
        transition: 'margin 0.01s ease',
        height: '100vh',
        minWidth: '320px',
    }

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed)
        if (collapsed) {
            localStorage.setItem('sidebar-collapsed', true)
        } else {
            localStorage.setItem('sidebar-collapsed', false)
        }
        setdefaultCollapsed(!defaultCollapsed)
    }

    const handleToggleSidebar = (value) => {
        setToggled(value)
    }

    if (jwtExpired) {
        toastLogin.current.show({ severity: 'error', summary: 'Login', detail: `Login has expired, please re-login.`, life: 3000 })
    }

    // if (serverDown) {
    //     return (
    //         <>
    //             <div style={{ display: 'flex', height: '100%', width: '100%' }}>
    //                 <Toast ref={toastDown} position="top-left" />
    //             </div>
    //             <h1>
    //                 <center>
    //                     Cannot communicate with the CASPA service.
    //                     <br />
    //                     Please try again later.
    //                 </center>
    //             </h1>
    //             <center>
    //                 <a href="./">Click Here to try again</a>
    //             </center>
    //         </>
    //     )
    // } else
    if (!token || token === '' || (!jwt && jwt === '') || !email || email === '' || jwtExpired) {
        if (globalVars.editMode) {
            globalVars.setEditMode(false)
        }
        return (
            <>
                <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                    <Toast ref={toastLogin} position="top-left" />
                </div>
                <Login />
            </>
        )
    }

    function MyFallbackComponent({ error, resetErrorBoundary }) {
        return (
            <div role="alert">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <button onClick={resetErrorBoundary}>Try again</button>
            </div>
        )
    }

    function logErrorToService(error, info) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: `And Error has occured: ${error} ${info}`, life: 3000 })
        // Use your preferred error logging service
        console.error('Caught an error:', error, info)
    }

    function closeErrorDialog() {
        setErrorDialogVisible(false)
        setErrorMessage('')
        setServerDown(false)
    }

    return (
        <>
            <div style={{ display: 'flex', height: '100%', width: '100%', minWidth: '800px' }}>
                <Suspense fallback={<div>Loading...</div>}>
                    <ErrorBoundary FallbackComponent={MyFallbackComponent} onError={logErrorToService}>
                        <Toast ref={toast} position="top-center" />
                        <Router>
                            <MySideNav
                                collapsed={collapsed}
                                toggled={toggled}
                                handleToggleSidebar={handleToggleSidebar}
                                handleCollapsedChange={handleCollapsedChange}
                                defaultCollapsed={defaultCollapsed}
                                disabled={!editMode}
                                collapseSidebar={collapseSidebar}
                            />
                            <div style={contentStyle}>
                                <Routes>
                                    <Route path="/" element={<Welcome />} />
                                    <Route path="/customergrid" element={<Customers />} />
                                    <Route path="/customers" element={globalVars.customerRow !== undefined && globalVars.customerRow !== null && globalVars.customerRow !== '' ? <CustomerPage /> : <Customers />} />
                                    <Route path="/customer/:customerId" element={<CustomerPage />} />
                                    <Route path="/customer/:customerId/:tabId" element={<CustomerPage />} />
                                    <Route path="/productgrid" element={<Products />} />
                                    <Route path="/products" element={globalVars.productRow !== undefined && globalVars.productRow !== null && globalVars.productRow !== '' ? <ProductPage /> : <Products />} />
                                    <Route path="/product/:productId" element={<ProductPage />} />
                                    <Route path="/product/:productId/:tabId" element={<ProductPage />} />
                                    <Route path="/versiongrid" element={<Versions />} />
                                    <Route path="/versions" element={globalVars.versionRow !== undefined && globalVars.versionRow !== null && globalVars.versionRow !== '' ? <VersionPage /> : <Versions />} />
                                    <Route path="/version/:versionId" element={<VersionPage />} />
                                    <Route path="/remoteconnections" element={<RemoteConnections />} />
                                    <Route path="/systempasswords" element={<SystemPasswords />} />
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/forecastrevenue" element={<ForecastRevenue />} />
                                    <Route path="/notinvoiced" element={<NotInvoiced />} />
                                    <Route path="/deferredvalue" element={<DeferredValue />} />
                                    <Route path="/usergrid" element={<Users />} />
                                    <Route path="/users" element={<Users />} />
                                    <Route path="/user/:userId" element={<UserPage />} />
                                    <Route path="/groupgrid" element={<Groups />} />
                                    <Route path="/groups" element={<Groups />} />
                                    <Route path="/group/:groupId" element={<GroupPage />} />
                                    <Route path="/access" element={<Access />} />
                                    <Route path="/maintenance" element={<Maintenance />} />
                                    <Route path="/whatsnew" element={<WhatsNew />} />
                                    <Route path="/boss" element={<BOSS />} />
                                    <Route path="/schema" element={<Schema />} />
                                    {/* <Route path="/options" element={<Options />} /> */}
                                    <Route path="/expired" element={<Expired />} />
                                    <Route path="*" element={<Welcome />} />
                                </Routes>
                            </div>
                        </Router>
                    </ErrorBoundary>
                    <Dialog
                        visible={errorDialogVisible && errorMessage !== undefined}
                        onHide={() => closeErrorDialog()}
                        style={{ width: '600px', minWidth: '600px', maxWidth: '600px' }}
                        header="Error"
                        draggable={false}
                        dismissableMask
                        showHeader={true}
                    >
                        <div style={{ color: 'red', fontWeight: 'bold', fontSize: '1.25em' }}>
                            <div style={{}}>{errorMessage}</div>
                        </div>
                    </Dialog>
                </Suspense>
            </div>
        </>
    )
}

export default App
