import { encode as base64_encode } from 'base-64'

export class GetUsersWithAccess {
    getData(email, jwt, userId) {
        let auth = email + ':' + jwt
        return fetch(process.env.REACT_APP_DATASNAP_URL + `GetUsersWithAccess/${userId}`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                Authorization: 'Basic ' + base64_encode(auth),
                'Content-Type': 'application/json',
            },
        })
    }

    emailTOTP(email, jwt) {
        let auth = email + ':' + jwt
        return fetch(process.env.REACT_APP_DATASNAP_URL + `EmailTOTP/${email}`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                Authorization: 'Basic ' + base64_encode(auth),
                'Content-Type': 'application/json',
            },
        })
    }
}
