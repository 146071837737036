import { Link } from "react-router-dom";
import globalVars from '../variables/globalVars';


function Expired() {
    const Logout = () => {
        globalVars.setJwt("");
        globalVars.setEmail("");
        globalVars.setToken("");
    }

    globalVars.setEditMode(true);
        
    return (
        <div className="page">
            <h1>Login has Expired</h1>
            <h3>Click <Link onClick={Logout}>Here</Link> to Login</h3> 
        </div>
    )
}

export default Expired;