import globalVars from '../variables/globalVars'
import { encode as base64_encode } from 'base-64'

export async function loginUser(username, password) {
    let auth = username + ':' + password
    return fetch(process.env.REACT_APP_DATASNAP_URL + 'Login/' + username + '/' + password, {
        mode: 'cors',
        withCredentials: true,
        crossorigin: true,
        method: 'GET',
        headers: {
            Authorization: 'Basic ' + base64_encode(auth),
            'Content-Type': 'application/json',
        },
    })
        .then((data) => data.json())
        .catch((error) => {
            globalVars.handleError(error.message)
        })
}

export async function EmailTOTP(username) {
    let auth = username + ':CASPAR'
    return fetch(process.env.REACT_APP_DATASNAP_URL + 'EmailTOTP/' + username, {
        mode: 'cors',
        withCredentials: true,
        crossorigin: true,
        method: 'GET',
        headers: {
            Authorization: 'Basic ' + base64_encode(auth),
            'Content-Type': 'application/json',
        },
    })
        .then((data) => data.json())
        .catch((error) => {
            globalVars.handleError(error.message)
        })
}
