import { useState } from 'react';

export default function usePersistentState(defaultValue, key) {
    let initVal;
    try {
      initVal = JSON.parse(localStorage.getItem(key));
    } catch {}
    if (initVal === undefined) initVal = defaultValue;
  
    const [state, setState] = useState(initVal);
    function persistState(value) {
      if (typeof value === "function") value = value(state);
      localStorage.setItem(key, JSON.stringify(value));
      setState(value);
    }
    return [state, persistState];
  }