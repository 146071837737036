import React, { useState, useRef, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import '../css/Login.css'
import { ReactComponent as YourSvg } from '../images/caspa.svg'
import { Toast } from 'primereact/toast'
import AppContext from '../AppContext'
import globalVars from '../variables/globalVars'
import { GetUsersWithAccess } from '../access/getUsersWithAccess'
import Cookies from 'js-cookie'
import { InputText } from 'primereact/inputtext'
import { BlockUI } from 'primereact/blockui'
import { loginUser, EmailTOTP } from '../access/Login'

function Login() {
    const [blocked, setBlocked] = useState(false)
    let email = Cookies.get('email')

    const [loading, setLoading] = useState(false)

    const [otp, setOtp] = useState('')
    const [username, setUserName] = useState(email)
    const [hasErrored, setHasErrored] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const toast = useRef(null)

    const styleEdit = {
        width: '260px',
        widthMin: '260px',
        borderWidth: '1px',
        fontSize: '12px',
        marginBottom: '4px',
    }

    const getUsersWithAccess = new GetUsersWithAccess()

    function ShowError(props) {
        return <p> {errorMessage} </p>
    }

    const emailTOTP = async (e) => {
        setBlocked(true)
        const token = await EmailTOTP(username)
        setBlocked(false)

        if (token !== undefined && token.result[0] !== undefined && token.result[0][0].Error === '') {
            toast.current.show({
                severity: 'info',
                summary: 'T.O.T.P.',
                detail: `${token.result[0][0].Result}`,
                life: 3000,
            })
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'T.O.T.P.',
                detail: `${token.result[0][0].Error}`,
                life: 3000,
            })
            console.log(token)
        }
    }

    const handleSubmit = async (e) => {
        if (e !== undefined) {
            e.preventDefault()
        }
        const token = await loginUser(username, otp)

        if (token !== undefined && token.result[0] !== undefined && token.result[0].Response === 200) {
            AppContext.userId = token.result[0].UserID
            AppContext.name = token.result[0].Name

            globalVars.setUserId(token.result[0].UserID)

            setLoading(true)

            getUsersWithAccess
                .getData(username, token.result[0].JWT, AppContext.userId)
                .then((response) => {
                    if (response.status === 403) {
                        // forbidden - bad token
                        globalVars.setJwtExpired(false)
                    }
                    setLoading(false)
                    return response.json()
                })
                .then((data) => {
                    globalVars.setCurrentLogin(data.result[0][0])
                    globalVars.setEditMode(false)
                    globalVars.setJwtExpired(false)
                    globalVars.setJwt(token.result[0].JWT)
                    Cookies.set('email', username, { expires: 7 })
                    globalVars.setEmail(username)
                    globalVars.setErrorMessage(undefined)
                    if (globalVars.setToken !== undefined) {
                        globalVars.setToken(token)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    globalVars.handleError(error.message)
                    if (globalVars.setToken !== undefined) {
                        globalVars.setToken(undefined)
                    }
                    if (globalVars.setToken !== undefined) {
                        globalVars.setCurrentLogin(undefined)
                    }
                    setLoading(false)
                    setErrorMessage('Login Failed')
                })
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Login',
                detail: `Error: Unable to verify Login`,
                life: 3000,
            })
            setErrorMessage('Login Failed')
            setHasErrored(true)
        }
    }

    const handleChange = (enteredOtp) => {
        setHasErrored(false)
        setOtp(enteredOtp)
    }

    useEffect(() => {
        if (!loading) {
            globalVars.setJwt('')
            globalVars.setEmail('')
            globalVars.setToken('')
        }
    }, [loading])

    if (loading) {
        return <p>Loading... </p>
    } else {
        return (
            <div className="app">
                <Toast ref={toast} position="top-center" />
                <BlockUI blocked={blocked} fullScreen />
                <div className="login-form">
                    <div className="myHeading">
                        <YourSvg />
                        <h3>&nbsp;CASPA Login</h3>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="input-container">
                            <label>
                                <p>Email Address</p>
                                <InputText id="EmailAddress" autoFocus={username === ''} value={username} style={styleEdit} onChange={(e) => setUserName(e.target.value)} />
                            </label>
                        </div>
                        <div className="input-container">
                            <label>
                                <p>Enter Authenticator code</p>
                                <InputText
                                    id="Authenticatorcode"
                                    autoFocus={username !== ''}
                                    value={otp}
                                    style={styleEdit}
                                    onChange={(e) => handleChange(e.target.value)}
                                    keyfilter="int"
                                    placeholder="Enter Authenticator code"
                                    autoComplete="off"
                                    maxLength={6}
                                />
                            </label>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'red',
                            }}
                        >
                            {hasErrored && errorMessage && <ShowError message={errorMessage} />}
                        </div>
                        <div className="button-container">
                            <Button className="btn margin-top--large" type="submit">
                                Login
                            </Button>
                        </div>
                        <div className="button-container">
                            <Button className="btn margin-top--large" onClick={emailTOTP}>
                                Email Code
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

export default Login
